import React, { Component } from 'react';

class Header extends Component{
    render(){
        return(
            <img className="appLogo" src="./image/m-box.png" alt="mIDentity Box" />
        )
    }
}

export default Header;