import React, { Component } from 'react';

class LoaderFull extends Component {
 
    render(){
        const showLoader = this.props.showLoader;
        return(
            <div>
                {
                    showLoader ? (
                        <div className="lds-ring-full"><div></div><div></div><div></div><div></div></div>
                    ) : (<div></div>)
                }
            </div>
        )
    }
}

export default LoaderFull;