import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { getCurrentUser } from './model/Functions';
import Home from './component/Home';
import Accounts from './component/Accounts';
import ListTenants from './component/TenantList';
import Register from './component/Register';

// const PrivateRoute = ({ component: Component, ...rest }) => (
//   <Route {...rest} render={props => (
//     getCurrentUser() ? (
//       <Component {...props} />
//     ) : (
//         <Redirect to={{
//           pathname: '/',
//           state: { from: props.location }
//         }} />
//       )
//   )} />
// )
const NonPrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    getCurrentUser() ? (
      <Redirect to={{
        pathname: "/auth",
        state: { from: props.location }
      }} />
    ) : (
        <Component {...props} />
      )
  )} />
)

function App() {
  return (
    <Router>
      <NonPrivateRoute exact path="/" component={Home} />
      <NonPrivateRoute exact path="/accounts" component={Accounts} />
      <NonPrivateRoute exact path="/showtenant" component={ListTenants} />
      <NonPrivateRoute exact path="/register" component={Register} />
    </Router>
  );
}

export default App;
