import React, { Component } from 'react';
import { Routes } from '../model/Config';
import { Link, Redirect } from 'react-router-dom';
import Header from '../shared/Header';
import ErrorMessages from '../model/Messages';

class Register extends Component{
    constructor(props){
        super(props);
        this.state = {
            userId: null,
            errorMessage: false,
            redirect: false
        }
    }

    gotoUser = () => {
        let userEmail = document.getElementById("userid").value;
        if(userEmail.length > 1){
            this.setState({
                errorMessage: false,
                userId: userEmail,
                redirect: true
            })
        } else {
            this.setState({ errorMessage: true })
        }
        
        // if(Validation.CheckEmail(userEmail)){
        //     this.setState({
        //         errorMessage: true,
        //         userId: userEmail,
        //         redirect: true
        //     })
        // } else {
        //     this.setState({ errorMessage: true })
        // }
    }

    render(){
        const { userId, errorMessage, redirect } = this.state;
        if(redirect){
            localStorage.setItem("doLogin", true);
            return <Redirect to={{ pathname: Routes.showTenant, username: userId }} />
        }
        return(
            <div className="mdWrapper">
                <Header />
                <h3>Create Account</h3>
                <div className="mdNewAccount">
                    <label>Username or Email</label>
                    <div className="form-group">
                        <input id="userid" placeholder="joe or joe@email.com" className={errorMessage ? "txt-input inputError" : "txt-input"} type="text" />
                        <span>
                            <Link to="/">
                                <img src="image/switch-account-black.svg" alt="Switch Account"/>
                            </Link>
                        </span>
                    </div>
                    {errorMessage ? (<p className="errorMessage">{ErrorMessages.MandatoryUser}</p>) : ""}
                    <button onClick={this.gotoUser} className="btn-normal">Continue</button>
                </div>
            </div>
        )
    }
}

export default Register;