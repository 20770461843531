import React, { Component } from 'react';
import { Routes } from '../model/Config';
import { Link, Redirect } from 'react-router-dom';
import Loader from '../shared/Loader';
import Header from '../shared/Header';

var $ = require("jquery");

let baseUrl = "", tenantListUrl = "";

if(window._env_ && window._env_.IAM_URL){
    baseUrl = window._env_.IAM_URL;
} else {
    // baseUrl = "https://{ecoId}.<SET HOST WHILE running in local>/digitanium/v2/auth";
    // alert("TenantList : IAM URL not configured");
}

if(window._env_ && window._env_.TENANT_LIST_URL){
    tenantListUrl = window._env_.TENANT_LIST_URL;
} else {
    // tenantListUrl = "https://master.<SET HOST WHILE running in local>/digitanium/v2/tenantlist/";
    // alert("TenantList : Tenant List URL not configured");
}
let searchUrl = localStorage.getItem("queryUrl") ? localStorage.getItem("queryUrl").replace("scope=openid", "scope=openid%20ast-activation") : "";
let activationUrl = baseUrl + searchUrl + "&";

//OIDC Params for getting token START
const redirectUrl = window.location.origin + "/showtenant";
const authUser = "mpower_activation_user";
const oidcClient = "ast-activation";
let tokenUrl = baseUrl.replace("{ecoId}", "master") + "?client_id=" + oidcClient + "&redirect_uri=" + redirectUrl + "&scope=openid&response_type=token&response_mode=fragment&nonce=" + Math.random().toString(36).slice(2) + "&username=" + authUser;
//OIDC Params for getting token END

class TenantList extends Component{
    constructor(props){
        super(props);
        if(props.location.username){
            localStorage.setItem("username", props.location.username)
        }
        this.state = {
            tenants: null,
            showLoader: true,
            username: props.location.username ? props.location.username : localStorage.getItem("username")
        }
    }

    componentWillMount(){
        var params = {};
        if(window.location.hash.indexOf("access_token") > -1){
            localStorage.setItem("doLogin", false);
            var hash = window.location.hash.substring(1);
            // eslint-disable-next-line
            hash.split('&').map(hk => {
            let temp = hk.split('='); 
                params[temp[0]] = temp[1];
            });
            if(params.access_token){
                var settings = {
                    "url": tenantListUrl + this.state.username,
                    "method": "GET",
                    "headers": {
                        "Authorization": "Bearer " + params.access_token,
                    }
                };
                $.ajax(settings).always((response) => {
                    if(response && response.data && response.data.includes("No results found")){
                        this.setState({
                            tenants: null
                        })
                    } else {
                        if(typeof(response.data) === "string"){
                            this.setState({
                                tenants: [response.data]
                            })
                            this.startActivation(response.data);
                        } else {
                            this.setState({
                                tenants: response.data,
                                showLoader: false
                            })
                        }
                    }
                })
            } else {
                alert("TenantList : Issue getting tenant list");
            }
            return;
        }
        if(localStorage.getItem("doLogin") === "true"){
            localStorage.setItem("doLogin", false);
            window.location.href = tokenUrl;
        }
    }

    editUser = () => {
        this.setState({
            redirect: true
        })
    }

    startActivation = function(tenant) {
        window.location.href = activationUrl.replace("{ecoId}", tenant) + "username=" + this.state.username;
    }
   
    render(){
        const { redirect, tenants, showLoader } = this.state;
        if(redirect){
            return <Redirect to={{ pathname: Routes.home }} />
        }
        return(
            <div className="mdWrapper">
                <div className="mdInnerWrap">
                    <Link className="goBack" to="/"><img src="image/arrow_back.svg" alt="Go Back"/></Link>
                </div>
                <Header />
                <div className="mdNewAccount noEdit">
                    <label>Username or Email</label>
                    <div className="form-group">
                        <input id="userid" placeholder="User Id" defaultValue={this.state.username} className="txt-input-disabled" type="text" disabled={true} />
                    </div>
                </div>
                {!showLoader ? (<div className="tenantList">
                    <h3>{tenants ? "Choose Tenant" : "No Tenants"}</h3>
                    {tenants && tenants.length > 0 ?
                    (<div className="mdTenants">
                        <ul className="mdTenantsList">
                            {tenants.map(function(tenant, index){
                                return (
                                    <li key={index} onClick={this.startActivation.bind(this, tenant)} className="tenantName">
                                        <div className="activeCaption">
                                            <span>{tenant.charAt(0).toUpperCase()}</span>
                                        </div>
                                        <div className="activeUser">
                                            <h4>{tenant}</h4>
                                        </div>
                                        <div className="activeAction"><img src="image/arrow_right.svg" alt=""/></div>
                                    </li>
                                )
                            }.bind(this))}
                        </ul>
                        <p className="moreActiveUsers">Scroll for more users</p>
                    </div>) : 
                    (<div className="noTenant">
                        <img src="image/no_tenants.svg" alt="No Tenants"/>
                        <h5>User is currently not mapped to any tenants.</h5>
                    </div>)}
                    {!tenants ? (<Link className="btn-normal" to="/register">Register</Link>) : "" }
                </div>) : <Loader />}
            </div>
        )
    }
}

export default TenantList;