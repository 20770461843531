class Login {
    constructor() {
      this.KWebToNative = window.KWebToNative;
    }
  
    getActivatedUsers() {
      this.KWebToNative.send("KsUiGetActivatedUsers", {});
      return new Promise((resolve, reject) => {
        this.KWebToNative.on("KsUiGetActivatedUsersResult", function(payload) {
          if (payload.data) {
            resolve(payload.data);
          }
        });
      });
    }
  
    getLastLoginUser() {
      this.KWebToNative.send("KsUiGetLastLoginUser", {});
      return new Promise((resolve, reject) => {
        this.KWebToNative.on("KsUiGetLastLoginUserResult", function(payload) {
          if (payload.data) {
            resolve(payload.data);
          }
        });
      });
    }
  
    deleteUser(data) {
      this.KWebToNative.send("KsUiDeleteUser", data);
      return new Promise((resolve, reject) => {
        this.KWebToNative.on("KsUiDeleteUserResult", function(payload) {
          if (payload.data) {
            resolve(payload.data);
          }
        });
      });
    }
  }
  
  const login = new Login();

  export default login;