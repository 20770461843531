import React, { Component } from 'react';
import { Routes } from '../model/Config';
import login from '../external/login';
import { Link, Redirect } from 'react-router-dom';
import Header from '../shared/Header';
import ErrorMessages from '../model/Messages';
import LoaderFull from '../shared/LoaderFull';
let $ = require("jquery");

let baseUrl;
if(window._env_ && window._env_.IAM_URL){
    baseUrl = window._env_.IAM_URL;
} else {
    // baseUrl = "https://{ecoId}.<SET HOST WHILE running in local>/digitanium/v2/auth";
    // alert("Home : IAM URL not configured");
}
let loginUrl;
let setLoaderVisible = false;
class Home extends Component {
    constructor(props){
        super(props);
        if(window.location.search !== ""){
            localStorage.setItem("queryUrl", window.location.search);
            setLoaderVisible = true;
        } else {
            localStorage.setItem("switchUser", "true");
        }
        let tempQueryUrl = localStorage.getItem("queryUrl") ? localStorage.getItem("queryUrl").replace("scope=openid", "scope=openid%20ast-login") : "";
        loginUrl = baseUrl + tempQueryUrl + "&";
        this.state = {
            loggedinUser: null,
            activeUsers: {"users":[]},
            userId: null,
            redirect: false,
            query: localStorage.getItem("queryUrl"),
            errorMessage: false,
            showFullLoader: setLoaderVisible
        }
        // this.state = {
        //     loggedinUser: {"user":{"firstName":"Achyuth","lastName":"T","ecoId":"grootandev","userId":"achyuth@test.com","image":"https://www.grootan.com/img/testimonials/achyuth.jpg"}},
        //     activeUsers: {"users":[{"firstName":"Achyuth","lastName":"T","ecoId":"achyuth0001","userId":"achyuth@test1.com","image":""}, {"firstName":"Achyuth","lastName":"T","ecoId":"achyuth0001","userId":"achyuth@grootan.com","image":"https://www.grootan.com/img/testimonials/achyuth.jpg"}]},
        //     userId: null,
        //     redirect: false,
        //     query: localStorage.getItem("queryUrl"),
        //     errorMessage: false
        // }
    }

    componentDidMount(){
        if( window.outerHeight > $(".mdWrapper").height()  && window.outerWidth < 768){
            $(".mdAccounts .accounts").addClass("fixedAccounts")
        } else {
            $(".mdAccounts .accounts").removeClass("fixedAccounts")
        }
        let self = this;
    
        login.getLastLoginUser().then(loggedInUser => {
            if(localStorage.getItem("switchUser") !== "true" && loggedInUser.user.userId !== ""){
                window.location.href = loginUrl.replace("{ecoId}", loggedInUser.user.ecoId) + "username=" + loggedInUser.user.userId;
            }

            if(localStorage.getItem("switchUser") === "true"){
                this.setState({
                    loggedinUser: loggedInUser,
                    showFullLoader: false
                })
            } else {
                this.setState({
                    loggedinUser: loggedInUser
                })
            }
        });

        login.getActivatedUsers().then(data => {
            if(localStorage.getItem("switchUser") !== "true" && this.state.loggedinUser == null && data.users.length > 0){
                window.location.href = loginUrl.replace("{ecoId}", data.users[0].ecoId) + "username=" + data.users[0].userId;
            }
            if(localStorage.getItem("switchUser") === "true"){
                this.setState({
                    activeUsers: data,
                    showFullLoader: false
                })
            } else {
                this.setState({
                    activeUsers: data
                })
            }
        });

        if(this.state.showFullLoader){
            setTimeout(function(){
                // alert("fallback");
                self.setState({
                    showFullLoader: false
                })
            }, 6000);
        }
    }

    gotoUser = () => {
        let userEmail = document.getElementById("userid").value;
        if(userEmail.length > 1){
            this.setState({
                errorMessage: false,
                userId: userEmail,
                redirect: true
            })
        } else {
            this.setState({ errorMessage: true })
        }
        
        // if(Validation.CheckEmail(userEmail)){
        //     this.setState({
        //         errorMessage: true,
        //         userId: userEmail,
        //         redirect: true
        //     })
        // } else {
        //     this.setState({ errorMessage: true })
        // }
    }

    authenticateLogin(user) {
        window.location.href = loginUrl.replace("{ecoId}", user.ecoId) + "username=" + user.userId;
    }

    render(){
        const { redirect, userId, loggedinUser, activeUsers, errorMessage } = this.state;
        if(redirect){
            localStorage.setItem("doLogin", true);
            return <Redirect to={{ pathname: Routes.showTenant, username: userId }} />
        }
        return(
            <div className="mdWrapper">
                <LoaderFull showLoader={this.state.showFullLoader}/>
                <Header />
                <h3>{loggedinUser ? "Active Accounts" : "Add Account"}</h3>
                {(loggedinUser || activeUsers.length > 0) ? 
                    (
                        <div className="mdAccounts">
                            {loggedinUser ? 
                             (
                                <ul className="mdCurrentAccount">
                                    <li onClick={this.authenticateLogin.bind(this, loggedinUser.user)}>
                                        <div className="loggedInCaption">
                                            {(loggedinUser.user.image === "" || loggedinUser.user.image === "data:image/jpeg;base64,") ? 
                                            (<span>{loggedinUser.user.firstName.charAt(0).toUpperCase()}</span>) : 
                                            (<img className="profilePicture" src={loggedinUser.user.image} alt="Logged In User Profile"></img>)}
                                        </div>
                                        <div className="loggedInUser">
                                            <h4>{loggedinUser.user.firstName + " " + loggedinUser.user.lastName}</h4>
                                            <p>{loggedinUser.user.ecoId}</p>
                                        </div>
                                        <div className="loggedInAction"><img src="image/arrow_right.svg" alt=""/></div>
                                    </li>
                                </ul>
                             ) : <div></div>
                            }
                            <p>OTHERS</p>
                            {activeUsers.users.length > 1 ? 
                            (<div className="mdOtherWrap">
                                <ul className="mdOtherAccount">
                                    {activeUsers.users.map((user, index) => {
                                        if(loggedinUser && user.userId !== loggedinUser.user.userId){
                                            return(
                                                <li key={index} onClick={this.authenticateLogin.bind(this, user)}>
                                                    <div className="activeCaption">
                                                        {(user.image === "" || user.image === "data:image/jpeg;base64,") ? 
                                                        (<span>{user.firstName.charAt(0).toUpperCase()}</span>) : 
                                                        (<img className="profilePicture" src={user.image} alt="Active User Profiles"></img>)}
                                                    </div>
                                                    <div className="activeUser">
                                                        <h4>{user.firstName + " " + user.lastName}</h4>
                                                        <p>{user.ecoId}</p>
                                                    </div>
                                                    <div className="activeAction"><img src="image/arrow_right.svg" alt=""/></div>
                                                </li>
                                            )
                                        } else {
                                            return "";
                                        }
                                    })}
                                </ul>
                                <p className="moreActiveUsers">Scroll for more users</p>
                            </div>) : "" }
                            <div className="accounts addAccount"><Link to="/register"><img src="image/add_account.svg" alt="Add New Account"/></Link></div>
                        </div>
                    ) : 
                    (
                        <div className="mdNewAccount">
                            <label>Username or Email</label>
                            <div className="form-group">
                                <input id="userid" placeholder="joe or joe@email.com" className={errorMessage ? "txt-input inputError" : "txt-input"} type="text" />
                                {activeUsers.users.length > 1 ? (<Link to="/"><img src="image/switch_account.svg" alt="Switch Account"/></Link>) : ""}
                            </div>
                            {errorMessage ? (<p className="errorMessage">{ErrorMessages.MandatoryUser}</p>) : ""}
                            <button onClick={this.gotoUser} className="btn-normal">Continue</button>
                        </div>
                    )
                }
            </div>
        )
    }
}

export default Home;