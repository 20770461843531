import React, { Component } from 'react';
import login from '../external/login';
import { Routes } from '../model/Config';
import { Redirect } from 'react-router-dom';
import Header from '../shared/Header';

class Accounts extends Component{
    constructor(props){
        super(props);
        this.state = {
            loggedinUser: null,
            activeUsers: {"users":[]}
        }
    }
    componentDidMount(){
        login.getLastLoginUser().then(data1 => {
            this.setState({
                loggedinUser: data1
            })
        });

        login.getActivatedUsers().then(data => {
            this.setState({
                activeUsers: data
            })
        });
    }

    gotoRegister = () => {
        this.setState({
            redirect: true
        })
    }
    
    render(){
        if(this.state.redirect){
            return <Redirect to={{ pathname: Routes.register }} />
        }
        return(
            <div className="mdWrapper">
                <Header />
                <h3>Choose Account To Sign In</h3>
                {
                    this.state.activeUsers.users.map((data, index) => 
                        <div key={index} className="accountContent">
                            <img className="profileIcon" src={(data.image === "" ? "./image/placeholder.jpg" : data.image)} alt="profile"></img>
                            <div className="userInfo">
                                <div>{data.firstName  + " " + data.lastName}</div>
                                <div>{data.userId}</div>
                                <div>{data.ecoId}</div>
                            </div>
                        </div>
                    )
                }
                <div className="accountContent addAccountContent">
                    <img className="profileIcon" src="./image/placeholder.jpg" alt="profile"></img>
                    <div className="userInfo" onClick={this.gotoRegister}>
                        <div>Add Another Account</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Accounts;